<template>
  <website-container-home-view />
</template>

<script>
import WebsiteContainerHomeView from "@/components/website/WebsiteContainerHome.vue";
export default {
  name: "website-container-home",
  components: {
    "website-container-home-view": WebsiteContainerHomeView
  },
  data() {
    return {};
  }
};
</script>

<style></style>
