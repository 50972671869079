<template>
  <div>
    <therapies-summary
      v-if="therapiesSummaryContent"
      :content="therapiesSummaryContent"
      @goToTherapySection="goToTherapySection"
    />
    <hr />
    <testimonials v-if="testimonialsContent" :content="testimonialsContent" />
    <hr />
    <info-left-card
      v-if="contactInfo"
      :info="contactInfo"
      :colorSet="colorSet.PRIMARY"
    />
  </div>
</template>

<script>
import TherapiesSummary from "./TherapiesSummary";
import Testimonials from "./Testimonials";
import InfoCardLeft from "./InfoCardLeft";

import { WEBSITE_SECTIONS } from "@/config/constants";
import { WEBSITE_COLOR_SET } from "@/config/constants";
import { WEBSITE_BUILDER_SECTIONS } from "@/config/constants";

export default {
  name: "website-home",
  components: {
    "therapies-summary": TherapiesSummary,
    testimonials: Testimonials,
    "info-left-card": InfoCardLeft
  },
  props: {
    homeSections: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      colorSet: WEBSITE_COLOR_SET,
      contactInfo: null,
      testimonialsContent: null,
      therapiesSummaryContent: null
    };
  },
  methods: {
    goToTherapySection() {
      this.$emit("goToTherapySection", WEBSITE_SECTIONS.THERAPIES);
    }
  },
  watch: {
    homeSections(newValue) {
      newValue.forEach(section => {
        switch (section.id) {
          case WEBSITE_BUILDER_SECTIONS.THERAPIES_SUMMARIES.id:
            {
              this.therapiesSummaryContent = section.websiteContents;
            }
            break;
          case WEBSITE_BUILDER_SECTIONS.TESTIMONIALS.id:
            {
              this.testimonialsContent = section.websiteContents;
            }
            break;
          case WEBSITE_BUILDER_SECTIONS.PROFILE_SUMMARY.id:
            {
              this.contactInfo = section.websiteContents
                ? section.websiteContents[0]
                : null;
            }
            break;
        }
      });
    }
  }
};
</script>

<style scoped></style>
